<template>
  <div>
    <!--begin::tax-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.laundry_services_pricing') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row p-0">
        <div class="col-md-12">
          <div class="d-flex justify-content-end mt-2 p-2 bg-FFB803" style="height: 45px;">
            <button type="button" class="btn btn-primary btn-sm" @click="addServices">{{ $t('add_more') }}</button>
          </div>
        </div>

        <div class="col-md-12">
          <div class="bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addServices" @keyup.alt.46="removeRowServices(0)">
              <thead>
              <tr>
                <th style="width:25%">{{ $t('laundry_services_pricing.service_name') }}</th>
                <th style="width:10%">{{ $t('laundry_services_pricing.price') }}</th>
                <th style="width:10%">{{ $t('laundry_services_pricing.number_of_pieces') }}</th>
                <th style="width:15%">{{ $t('laundry_services_pricing.tax') }}</th>
                <th style="width:15%">{{ $t('laundry_services_pricing.notes') }}</th>
                <th style="width:15%">{{ $t('laundry_services_pricing.status') }}</th>
                <th style="width:15%"></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in services" :key="index">
                <td>
                  <multiselect v-model="row.laundry_service" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('laundry_services_pricing.service_name')"
                               :placeholder="$t('laundry_services_pricing.service_name')"
                               label="name"
                               track-by="id"
                               :options="laundry_services"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <span v-if="validation && validation[index+'.laundry_service']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.laundry_service'][0] }}
                                    </span>
                </td>
                <td>
                  <input type="number" step="1.0" v-model="row.price" class="form-control" :class="validation && validation[index+'.price'] ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation[index+'.price']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.price'][0] }}
                                    </span>
                </td>
                <td>
                  <input type="number" step="1.0" v-model="row.qty" class="form-control" :class="validation && validation[index+'.qty'] ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation[index+'.qty']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.qty'][0] }}
                                    </span>
                </td>
                <td>
                  <multiselect v-model="row.tax" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.tax')"
                               :placeholder="$t('sales_invoices.tax')"
                               label="name"
                               track-by="id"
                               :options="taxes"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getTaxes($event)">
                  </multiselect>
                  <span v-if="validation && validation[index+'.tax']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.tax'][0] }}
                                    </span>
                </td>

                <td>
                  <input type="text" v-model="row.notes" class="form-control" :class="validation && validation[index+'.notes'] ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation[index+'.notes']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.notes'][0] }}
                                    </span>
                </td>
                <td>
                  <b-form-checkbox size="lg" v-model="row.is_active" name="check-button" switch></b-form-checkbox>
                </td>
                <td>
                  <v-icon style="color: #dc3545;" small @click="removeRowServices(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>

      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save()">
              {{ $t('save') }}
            </button>
          </div>
        </div>
      </div>

    </div>
    <!--end::tax-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-laundry-services-prices",
  data() {
    return {
      isEditing: false,
      mainRoute: 'settings/categories_list/laundry_services',
      mainRouteDependency: 'base/dependency',
      category_id: this.$route.params.category_id ? this.$route.params.category_id : '',


      service_from: {id: null, laundry_service: null, price: null, qty: null, is_active: null, notes: null},
      services: [],
      laundry_services: [],
      taxes: [],
      validation: null,


    };
  },
  watch: {
    // taxes: {
    //     handler(val) {
    //         let _arr = [];
    //         if (val && val.length)
    //             val.forEach((row) => {
    //                 if (row.tax)
    //                     _arr.push(row.tax.id);
    //             });
    //         if (this.taxesList.length)
    //             this.innerTaxesList = this.taxesList.filter(row => !_arr.includes(row.id));
    //     },
    //     deep: true,
    // },
  },
  methods: {
    save() {
      // if (this.isEditing) {
        this.update();
      // } else {
      //   this.create();
      // }
    },

    // create() {
    //   ApiService.post(this.mainRoute, {
    //     laundry: this.services,
    //   })
    //       .then((response) => {
    //
    //         this.validation = null;
    //         this.$router.push({name: 'categories-list.index'});
    //
    //         this.$successAlert(response.data.message)
    //       })
    //       .catch((error) => {
    //         this.validation = error.response ? error.response.data.errors : null;
    //         this.$errorAlert(error);
    //       });
    // },

    update() {
      ApiService.patch(`${this.mainRoute}/${this.category_id}`, {
        laundry: this.services,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'categories-list.index'});
            this.$successAlert(response.data.message)
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            this.$errorAlert(error);
          });
    },

    addServices() {
      this.services.unshift(this.service_from);
      this.service_from = {id: null, laundry_service: null, price: null, qty: null, is_active: null, notes: null};
    },

    removeRowServices(index) {
      this.services.splice(index, 1);
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/list/${this.category_id}`).then((response) => {
        this.services = response.data.data;
        if (response.data.data && response.data.data.length == 0){
          this.addServices();
        }
      });
    },
    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },
    // getLaundryServices(filter) {
    //   if (filter && filter.length >= 3) {
    //     ApiService.get(`${this.mainRouteDependency}/laundry_services`, {params: {filter: filter}}).then((response) => {
    //       this.laundry_services = response.data.data;
    //     });
    //   } else {
    //     this.laundry_services = [];
    //   }
    // },
    getLaundryServices() {
      ApiService.get(`${this.mainRouteDependency}/laundry_services`).then((response) => {
        this.laundry_services = response.data.data;
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.categories_list"), route: '/settings/categories/list'}, {title: this.$t("MENU.laundry_services_pricing")}]);
    this.getLaundryServices();

    if (this.category_id) {
      this.getData();
    }
  },
};
</script>


